import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import posed from 'react-pose'
import SiteTitle from '../components/site-title'
import Footer from '../components/footer'
import NavigationInterior from '../components/navigation-interior'

const PressSingle = posed.div({
    hoverable: true,
    init: {
        scale: 1
    },
    hover: {
        scale: 1.05,
    }
});

const PressPost= ({node}) => {
    return (
        <PressSingle className="blog-list-unit">
            <h4 className="italic"><Link to={node.slug}>{node.title}</Link></h4>
            <hr></hr>
        </PressSingle>
    )
}

const PressPage = ( props, pageContext ) => {
    return (
            <div className="single-post generic-container">
                <SiteTitle></SiteTitle>
                <div className ="single-post-inner post-list">
                    {props.data.allContentfulPress.edges.map((edge) =>
                        <PressPost key={edge.node.id} node={edge.node}
                        />)}
                    {props.pageContext && props.pageContext.hasNextPage && (
                        <div className="nav-next-page">
                            <p className="next-page-link">
                                <Link className="flex items-center" to={props.pageContext.nextPageLink}>
                                    next page
                                </Link>
                            </p>
                        </div>
                    )}
                    {props.pageContext && props.pageContext.pageNumber !== 1 && (
                        <div className="nav-next-page">
                            <p className="next-page-link">
                                <Link className="flex items-center" to={props.pageContext.previousPageLink}>
                                    previous page
                                </Link>
                            </p>
                        </div>
                    )}
                </div>
                <NavigationInterior></NavigationInterior>
                <Footer></Footer>
            </div>
    )
}

export default PressPage

export const pressQuery = graphql`
    query ($skip: Int, $limit: Int){
        allContentfulPress(
            skip: $skip
            limit: $limit
            filter: {
                node_locale: {eq: "en-US"}
            },
            sort: {
                fields: [createdAt], order: DESC
            }

        ) {
            edges {
                node {
                    id
                    title
                    createdAt(formatString: "MMMM DD, YYYY")
                    slug
                }
            }
        }
    }
`